
.site-main{

	//BTN Override
	.btn-override{
	    display: inline-block;
	    width: auto;
	    margin: 15px 0px;
	    padding-left: 40px;
	    padding-right: 40px;
	}

	.btn-default{
	    color: $wcm-white !important;
	    background-color: $wcm-dark-orange !important;
		border-color: $wcm-dark-orange !important;
	}

	//Custom external glyph
	.view-content{
		.views-row{
			.external-link:after{
				content: url(/sites/all/themes/wcm_dermatology_subtheme/img/external-link_red.png) !important;
			}

			/*.btn-default:after, .dndcinfo:after{
				content: url(/sites/all/themes/wcm_dermatology_subtheme/img/external-link_white.png) !important;
			}*/
		}
	}

	p .external-link:after{
		content: url(/sites/all/themes/wcm_dermatology_subtheme/img/external-link_red.png) !important;
	}


	/*p .btn-default:after{
		content: url(/sites/all/themes/wcm_dermatology_subtheme/img/external-link_white.png) !important;
	}*/

	//List Items
	.item-list{
		ul{
			li{
				margin-top: 12px;
				margin-bottom: 12px;
			}
		}
	}

	//Required Askterisk Color Override
	.marker, .form-required{
		color: $wcm-red;
	}
}
