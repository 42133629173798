.not-front{

	//Main Column
	.main-content{

		//Custom hr
		hr.hr_half{
		    width: 50%;
    		margin: 0px 0px 15px 0px;
		}

		//Profile List View
		.view-profiles{

			.select2-container{
				width: 400px !important;
			}

			.btn{
				background-color: $wcm-dark-orange !important;
				border-color: $wcm-dark-orange !important;

				&:hover{
					background-color: $wcm-red !important;
					border-color: $wcm-red !important;
				}
			}


			.views-row{
				display: inline-block;
				width: 100%;

				.profile-list-image{
					float: left;
					padding-right: 20px;
				}

				.views-field-field-pops-profile-link,
				.views-field-field-research-profile-link, 
				.views-field-field-lab-link{
					padding: 5px 0px;
				}

				.btn{
					font-size: 13px;
					width: 140px;
				    padding: 6px 10px;
				}


				h5.display-name{
					color: $wcm-red;
					margin: 0px;
				}

				.views-field-field-professional-title{
					font-style: italic;
				}

				.views-field-field-field-type{
					font-weight: bold;
				}

				.views-field-field-specialties-type{
					ul{
					    list-style-position: inside;
					}
				}
			}

			.views-row-first{
				padding-bottom: 10px;
			    margin-bottom: 20px;
			    border-bottom: solid 1px $wcm-border-gray;
			}
		}

		//Residents List View
		.view-residents{
			.views-row{
				display: inline-block;
				width: 100%;
				padding: 20px 0px;

				h3.title{
					margin: 0px;
				}
				.views-field-field-headshot-image{
					float: left;
    				padding-right: 30px;
				    
				}
				.views-label{
				    float: left;
    				padding-right: 5px;
    				font-weight: bold;
				}
			}
		}

		//Grand Rounds
		.grand_round{
			width: 100%;
			border: 0;
			cellspacing: 0; 
			cellpadding: 0;

			tr{
				td{
					vertical-align: top;
					padding: 5px;
					border-bottom: solid 1px $wcm-border-gray;
				}
			}
		}

		//Patient Care cta
		.pc-cta-img{
			float: left;
			padding-right: 25px;
		}

		.pc-cta-teaser{
			@include clearfix();
		}

		/*CLINICAL TRIAL*/
		.dndcinfo {
		    color: $wcm-white;
		    background-color: #cf4520;
		    text-decoration: none;
		    padding: 5px 10px;
		    line-height: 35px;
		    border-radius: 5px;
		    font-size: 13px;
		    &:hover {
			    text-decoration: none;
			    color: $wcm-white;
			    background-color: #e87722;
			}
		}

		.view-clinical-trial{
			//Button
			.views-row{
				border-bottom: solid 1px $wcm-border-gray;
				margin-bottom: 10px;
				// padding-bottom: 20px;
			}
			.views-row-last{
				border: none;
			}
			.clinical_trials_title, .trial-title {
				font-size: 17px;
				padding-bottom: 10px;
			    // font-weight: bold;
			    // line-height: 26px;
			    // margin: 20px 0px;
			    // a::after{
				// 	content: none;
				// }
			}

			.views-field {
				// padding-bottom: 2px;
				.views-label {
					font-weight: 600;
				}
				.field-content {
					display: inline;
				}
			}

			.study_details_button{
				font-size: 13px;
				padding: 20px 0px;
				a::after{
					content: none;
				}
			}
		}

		/*NEWS LIST VIEW*/
		.view-news{
			
			.select2-container{
				width: 400px !important;
			}

			.views-row{
				padding: 0px 0px 40px 0px;
				margin: 40px 0px 0px 0px;
			    border-bottom: solid 1px $wcm-border-gray;
				.teaser-image{
					img{
						float: right;
						margin: 0px 10px;
					}
				}
				h3.teaser-title{
					margin: 0px;
				}

				a.read-more{
					padding: 20px 0px 0px 0px;
		    		display: inline-block;
				}
			}

			.views-row-last{
				border-bottom: none;
			}
		}

		.field-name-field-news-category{
			h3.field-label{
				margin: 0px;
			}
		}

		ul.social-share{
			li{
				a{
					border-bottom: none;
					&.hover{
						text-decoration: none;
					}
				}
			}
		}
	}

	.information-column{
		.pane-title__override{
		    margin: 10px 0 5px;
			color: $wcm-med-gray;
			font-size: 13px;
			font-weight: 700;
			letter-spacing: 2px;
			text-transform: uppercase;
			text-align: center;
		}

		.btn{
			width: 255px;
		}
	}
}