.front{	
	//Section Headers
	.region_title{
	    font-size: 40px;
    	margin: 20px 0;
    	font-family: $wcm-bold;
	    font-weight: normal;
	    line-height: 1.1;
	    color: $wcm-red;
	}

	//Research Region
	.homepage_research_image{

		@include breakpoint ($xs){
			padding-bottom: 20px;
		}
		@include breakpoint ($sm){
			padding-bottom: 20px;
		}
		@include breakpoint ($md){
			border: solid 30px $wcm-white;
		}

		@include breakpoint ($lg){
			border: solid 30px $wcm-white;
		}
	}

	//Parallax only at desktop view
	/*@include breakpoint ($lg){
		.parallax-image {
			overflow: hidden;
			position: relative;
			height: 400px;

			img{
			  position: absolute;
			  top: 0;
			  left: 0;
			  width: 100%;
			  height: auto;
			  float: left;
			}
		}
	}
	*/

	@include breakpoint ($md){
		.parallax-image {
			overflow: hidden;
			position: relative;
			height: 400px;

			img{
			  position: absolute;
			  top: 0;
			  left: 0;
			  width: 100%;
			  height: auto;
			  float: left;
			}
		}
	}

	.homepage_research{
		padding: 35px;
		clear: both;

		.btn--wcm{
			float: left;
			margin-bottom: 20px;
		}
	}

	//Patient Care Region
	.patient_care_container{
		padding: 100px 0px;
		background: 
		    /* top, transparent red */ 
		    linear-gradient(
		      rgba(0, 0, 0, 0.5), 
		      rgba(0, 0, 0, 0.5)
		    ),
		    /* bottom, image */
		    url(/sites/all/themes/wcm_dermatology_subtheme/img/homepage_patient_care.jpg);

		background-repeat: no-repeat;
  		background-size: cover;
  		background-position: center center; 

  		&:hover{
  			background:
  			linear-gradient(
		      rgba(0, 0, 0, 0.3),
		      rgba(0, 0, 0, 0.3)
		    ),
		    /* bottom, image */
		    url(/sites/all/themes/wcm_dermatology_subtheme/img/homepage_patient_care.jpg);
		    background-repeat: no-repeat;
	  		background-size: cover;
	  		background-position: center center;
  		}

  		@include breakpoint ($lg){
  			margin-top: -30px;
  		}

  		.region_title{
  			font-size: 40px;
	    	margin: 20px 0;
	    	font-family: $wcm-bold;
		    font-weight: normal;
		    line-height: 1.1;
		    color: $wcm-red;
		    text-align: center;
		    color: $wcm-white;
			&:after{
				content: url(/sites/all/themes/wcm_dermatology_subtheme/img/external-link_white.png) !important;
				padding: 7px;
			}

			&:hover{
				text-decoration: none;
			}
		}

		p{
			color: $wcm-white;
			text-align: center;
		}
	}

	//Fontello font for cta's
	@import "../fonts/fontello/css/fontello-embedded";
	
	//CTA's
	@mixin homepage_cta(){
		margin: 20px 0px;
		overflow: hidden;
		height: auto;

		@include breakpoint ($xs){
			padding-left: 0px;
			padding-right: 0px;
		}
		@include breakpoint ($sm){
			padding-left: 0px;
			padding-right: 0px;
		}
		@include breakpoint ($md){
			margin-top: -30px;
			margin-bottom: 0px;
			border: solid 30px $wcm-white;
			box-shadow: 1px 2px 2px 1px $wcm-border-gray;
			height: 410px;
		}

		@include breakpoint ($lg){
			margin-top: -30px;
			margin-bottom: 0px;
			border: solid 30px $wcm-white;
			box-shadow: 1px 2px 2px 1px $wcm-border-gray;
			height: 410px;
		}

		.cta{
			.fieldable-panels-pane{
				div{
					overflow: hidden;
				}
				h3.title{
					text-align: center;
					margin: 30px 0 0;
				}

				a{
					border-bottom: none;

					&:after{
						content: '\f105' !important;
						font-family: "fontello";
						position: absolute;
						color: #ef8e3c;
						font-size: 25px;
						line-height: 30px;
				        height: 50px;
		    			width: 50px;
						text-align: center;
						display: block;
						left: 45%;
					    margin-top: -25px;
					    border: solid 10px $wcm-white;
				        border-radius: 25px;
					    background-color: $wcm-white;
					    margin-bottom: 35px;
				        padding-left: 2px;
					}

					&:hover{
						&:after{
							border: solid 10px $wcm-border-gray;
					        background-color: $wcm-border-gray;
					        transform: scale(1.1) rotate(4deg);
					        transition: .5s ease;
						}
					}
				}

				img.panopoly-image-full{
					height: 198px;
					width: 100%;
					overflow: hidden;
					transition: filter .5s ease-in-out;
					filter: brightness(100%); /* FF 35+ */


					@include breakpoint ($xs){
						height: auto;
					}
					@include breakpoint ($sm){
						height: auto;
					}

					&:hover{
						filter: brightness(120%); /* FF 35+ */
						transform: scale(1.1);
						transition: .5s ease;
					}
				}
			}
		}
	}

	.homepage_faculty{
		@include homepage_cta();
		@include breakpoint ($md){
			margin-right: 30px;
		}

		@include breakpoint ($lg){
			margin-right: 30px;
		}
	}

	.homepage_residency{
		@include homepage_cta();
		@include breakpoint ($md){
			margin-left: 30px;
		}

		@include breakpoint ($lg){
			margin-left: 30px;
		}
	}

}  