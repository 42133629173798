/**
 * Styles targeting the Event content type displays
 *
 */

 //Calendar Styles
.view .date-nav-wrapper .date-prev{
	right: 119px;
	
	@include breakpoint($xs) {
	  right: auto;
	}
}

.view .date-nav-wrapper .date-heading h3 {
	text-align: left;
	padding-top: 8px;
}


.view-events-calendar {
	margin-top: 20px;
}
  
.view .date-nav-wrapper .date-prev, .view .date-nav-wrapper .date-next{
	background-color: #fff;
	border-radius: 8px;
	
	position: relative;
	@include breakpoint($sm) {
		position: absolute;
	}
	
	a{
		background-color: $wcm-dark-orange;
		color: #fff;
		border: none;
		border-radius: 8px;
		padding: 0 35px;
		height: 30px;
		line-height: 30px;
		margin: 0;
	}
}

.pane-events-calendar-panel-pane-1 {

	.view-content {
		.calendar-calendar {
				width: 100%;
				overflow-y: hidden;
				overflow-x: auto;
				-ms-overflow-style: -ms-autohiding-scrollbar;
				border: 1px solid #ddd;
				-webkit-overflow-scrolling: touch;
		}
	}
}

.calendar-calendar .month-view .full td.single-day div.monthview{
	padding: 5px;
	border-radius: 0;
}

.calendar-calendar .month-view .full td.single-day .calendar-empty, 
.calendar-calendar .month-view .full td.single-day.empty, 
.calendar-calendar .month-view .full td.date-box.empty{
  // background-color: #fff;
  .day{
    color:$wcm-med-gray;
  }
}

.calendar-calendar .month-view .full td.date-box.today, .calendar-calendar .month-view .full tr td.single-day.today,
.calendar-calendar .month-view .full tr td.today, .calendar-calendar .month-view .full tr.odd td.today, .calendar-calendar .month-view .full tr.even td.today {
    border-color: $wcm-dark-orange;
}

.calendar-calendar td span.date-display-single, .calendar-calendar td span.date-display-start, .calendar-calendar td span.date-display-end, .calendar-calendar td span.date-display-separator, .calendar-calendar td a {
	display: inline;
}

.today .inner>div {
	border-left: none !important;
}

/* =Event List
----------------------------------------------------------*/

.event-list {
  margin: 30px 0;
}

.event-list--event {
  margin: 0 0 30px;
}

.event-list .event__title {
  margin-top: 10px;
}

.event-list .event__date {
  color: $wcm-med-gray;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.3;
  font-family: "1898Sans-Bold",sans-serif;
  letter-spacing: 2px;
}
